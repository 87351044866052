import React from 'react'
import MetaDecorator from '../components/MetaDecorator';

const PageNotFound = () => {
  return (
    <>
      <MetaDecorator
        title={`404 - Inclusive Digital Scorecard`}
        description="The Inclusive Digital Economy Scorecard (IDES) is a strategic performance and policy tool that has been developed to support countries in better understanding and monitoring the status of their digital transformation, with a view to helping them make it more inclusive. The IDES identifies the key market constraints hindering the development of an inclusive digital economy and helps governments to set the right priorities with public and private stakeholders, to foster accelerated development of a digital economy that leaves no one behind."
        imageUrl={"https://www.uncdf.org/download/file/8193?defaultFile=%2FDefaultImages%2FdefaultImage.png&thumbnail=False&cultureId=127&useLarge=true"}
        imageAlt={"UNCDF Logo"}
      />
      <main className="w-full h-[calc(100vh-80px)] bg-brand">
        <section className="w-full h-full grid place-items-center">
          <div className="text-white">
            <h1 className="text-3xl">404 | Page Not Found.</h1>
          </div>
        </section>
      </main>
    </>
  );
}

export default PageNotFound;
