import React, {
  useContext,
  useState,
} from "react";
import { AiFillCaretDown } from "react-icons/ai";
import {
  Link,
  useNavigate,
} from "react-router-dom";
import { YearContext } from "../../context/YearContext";
const MobileMenu = ({ isOpen }) => {
  const navigate = useNavigate();
  const [aboutToggle, setAboutToggle] =
    useState(false);
  const [countryToggle, setCountryToggle] =
    useState(false);
  const { countries, year } =
    useContext(YearContext);
  const handleClick = (
   country_code
  ) => {
    isOpen();
    navigate(`/${year}/${country_code}`);
  };

  const DisplayCountries = () => (
    <li>
      <ul className="grid grid-cols-2 text-sm grid-flow-row gap-x-4 gap-y-2 tracking-wide">
        {countries
          ? countries.map((country, index) => (
              <li
                key={country.id}
                className="flex items-center justify-end gap-2 tracking-wider"
                onClick={() =>
                  handleClick(
                    country.country_code
                  )
                }
              >
                <img
                  src={country.country_flag}
                  className="w-4 h-full object-fit"
                  alt={`flag of ${country.country_name}`}
                />
                <p className=" first-letter:font-bold">
                  {country.country_name}
                </p>
              </li>
            ))
          : null}
      </ul>
    </li>
  );

  return (
    <nav className="w-full bg-brand h-auto absolute top-20 right-0 px-4">
      <div className="h-auto w-full relative overflow-hidden">
        <div className="w-full h-auto ">
          <ul className="text-end p-4 text-white space-y-2 text-lg flex flex-col">
            <li onClick={isOpen}>
              <Link to="/">Dashboard</Link>
            </li>
            <li className="w-fit self-end">
              <button
                className="flex items-center"
                onClick={() =>
                  setCountryToggle(
                    (prev) => !prev
                  )
                }
              >
                <AiFillCaretDown className="mr-1" />
                Countries
              </button>
            </li>
            {countryToggle ? (
              <DisplayCountries />
            ) : null}

            <li onClick={isOpen}>
              <Link to="/reports">Reports</Link>
            </li>
            <li className="w-fit self-end ">
              <button
                className="flex items-center"
                onClick={() =>
                  setAboutToggle((prev) => !prev)
                }
              >
                <AiFillCaretDown className="mr-1" />
                About
              </button>
            </li>
            {aboutToggle ? (
              <li className="tracking-wider">
                <ul className="text-sm pr-2 space-y-2  rounded p-2">
                  <li>
                    <Link
                      to="/about/scorecard"
                      onClick={isOpen}
                    >
                      Scorecard
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/about/methodology"
                      onClick={isOpen}
                    >
                      Methodology
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/about/market-development-stages"
                      onClick={isOpen}
                    >
                      Market Development Stages
                    </Link>
                  </li>
                </ul>
              </li>
            ) : null}

            <li onClick={isOpen}>Contact</li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default MobileMenu;
