import React from 'react'

const ContactHeader = ({text}) => {
  return (
    <div className="border-b border-slate-800">
      <h2 className="text-xl font-bold pb-2">{text}</h2>
    </div>
  );
}

export default ContactHeader