import React from "react";

const AboutCard = ({children, customClass, withBackground = true}) => {
  return (
    <div className={`w-full h-auto rounded flex justify-center px-12 py-8 items-center gap-4 shadow-xl border border-white/10 ${withBackground ? 'bg-[#29388e] text-white' : 'bg-white text-black'}  ${customClass}`}>
      {children}
    </div>
  );
};

export default AboutCard;
