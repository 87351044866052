import React from "react";
import BreadCrumbs from "../../components/BreadCrumbs";
import Card from "../../components/Cards/Card";
import { ReactComponent as Eu } from "./../../assets/memberLogo/eu.svg";
import { ReactComponent as Gsma } from "./../../assets/memberLogo/gsma.svg";
import { ReactComponent as Unctad } from "./../../assets/memberLogo/unctad.svg";
import undesa from "./../../assets/memberLogo/undesa.png";
import undp from "./../../assets/memberLogo/UNDP.png";
import adb from "./../../assets/memberLogo/adb.png";
import { Link } from "react-router-dom";
import ExternalLink from "../../components/ExternalLink";
import CardContainer from "../../components/Cards/CardContainer";
import { RiDashboard2Fill } from "react-icons/ri";
import {HiChip} from 'react-icons/hi'
import {TbArrowsDownUp} from 'react-icons/tb'
import idesGraphics from './../../assets/idesGraphics.png'
import Footer from "../../components/Footer";
import AboutLayout from '../../components/Layout/AboutLayout'
import MetaDecorator from '../../components/MetaDecorator'
const Scorecard = () => {
  return (
    <>
      <MetaDecorator
        title="About the Scorecard - Inclusive Digital Scorecard"
        description="The Inclusive Digital Economy Scorecard (IDES) is a strategic performance and policy tool that has been developed to support countries in better understanding and monitoring the status of their digital transformation, with a view to helping them make it more inclusive."
      />
      <div className="w-full h-full ">
        <main className="mx-auto">
          <AboutLayout>
            <BreadCrumbs path={"scorecard"} />
            <h1
              className="xl:text-6xl lg:text-6xl text-4xl font-bold font-sans xl:leading-loose 
              lg:leading-loose leading-normal text-brand">
              About the Scorecard
            </h1>
            <p className="leading-loose text-lg">
              The Inclusive Digital Economy Scorecard (IDES) is a strategic performance and policy
              tool that has been developed to support countries in better understanding and
              monitoring the status of their digital transformation, with a view to helping them
              make it more inclusive. The IDES identifies the key market constraints hindering the
              development of an inclusive digital economy and helps governments to set the right
              priorities with public and private stakeholders, to foster accelerated development of
              a digital economy that leaves no one behind.
            </p>
            <p className="leading-loose text-lg">
              The IDES is a strategic performance tool developed by the{" "}
              <a
                href="https://www.uncdf.org/"
                referrerPolicy="noreferrer"
                className="underline hover:text-black/60"
                title="Links to UNCDF site.">
                United Nations Capital Development Fund (UNCDF)
              </a>
              , supported by a reference group of like-minded organizations (GSMA, EU, UNDP, UNCTAD,
              UN-DESA and ADB) and governments in four least developed countries (LDCs): Burkina Faso,
              Nepal, Solomon Islands and Uganda. The input from the reference group has helped to
              refine the scorecard and its indicators and, more broadly, drive forward the agenda of
              the measurement of inclusive digital economies.{" "}
            </p>
            <p className="leading-loose text-lg">
              Starting in 2021, the IDES has been implemented in 25 countries in Africa, Asia and
              the Pacific, and will be expanded to additional countries in 2022. Governments will be
              supported in measuring their progress over time, with the IDES data and scores for
              each country collected on an annual basis.{" "}
            </p>
            <p className="leading-loose text-lg">
              All the country-level IDES scores can be accessed via the{" "}
              <Link
                to="/dashboard"
                className="font-bold underline hover:text-black/60"
                title="Link to Map/Dashboard">
                map
              </Link>{" "}
              and the reports in the library.{" "}
            </p>
            <div className="py-8">
              <h3 className="font-bold text-center">IDES Reference Group Member</h3>
              <div className="grid grid-cols-[repeat(auto-fit,minmax(80px,1fr))] gap-8 pt-8 items-center justify-center">
                <ExternalLink href="https://www.undp.org/">
                  <img src={undp} alt="UNDESA Logo" className="w-auto h-auto object-contain" />
                </ExternalLink>
                <ExternalLink href="https://european-union.europa.eu/index_en">
                  <Eu className="w-24 h-auto" />
                </ExternalLink>
                <ExternalLink href="https://www.gsma.com/">
                  <Gsma className="w-24 h-auto" />
                </ExternalLink>
                <ExternalLink href="https://unctad.org/">
                  <Unctad className="w-24 h-auto" />
                </ExternalLink>
                <ExternalLink href="https://www.un.org/en/desa" referrerPolicy="no-referrer">
                  <img src={undesa} alt="UNDESA Logo" className="w-24 h-auto object-contain" />
                </ExternalLink>
                <ExternalLink href="https://www.adb.org" referrerPolicy="no-referrer">
                  <img src={adb} alt="ADB" className="w-24 h-auto object-contain" />
                </ExternalLink>
              </div>
            </div>
          </AboutLayout>
          <section className=" py-16 bg-[#29388e]">
            <div className="container mx-auto">
              <CardContainer>
                <Card
                  heading="MEASURE AND TRACK"
                  icon={<RiDashboard2Fill className="w-16 h-16" />}
                  alt="Measure and Track icon">
                  <p>
                    The <strong>level of development</strong> of inclusive digital economies at
                    country level.
                  </p>
                </Card>
                <Card
                  heading="IDENTIFY"
                  icon={<HiChip className="w-16 h-16" />}
                  alt="Identify icon">
                  <p>
                    The <strong>key market constraints</strong> hindering the development of
                    inclusive digital economies
                  </p>
                </Card>
                <Card
                  heading="HELP"
                  icon={<TbArrowsDownUp className="w-16 h-16" />}
                  alt="Help icon">
                  <p>
                    <strong>Set the right priorities</strong> with public and private stakeholders
                    in each country.
                  </p>
                </Card>
              </CardContainer>
            </div>
          </section>
          <AboutLayout>
            <h1 className="text-center py-6 text-2xl font-semibold mx-auto leading-relaxed text-[#131a42] font-sans">
              The IDES has been developed to support countries in better understanding the status of
              their digital transformation together with main priorities to accelerate the
              development and make it more inclusive.
            </h1>
          </AboutLayout>
          <section className="py-16 bg-[#29388e]">
            <div className="container mx-auto text-white flex gap-12 flex-wrap-reverse items-center justify-center">
              <div className="xl:w-3/6 lg:w-3/6 w-5/6 mx-auto">
                <h1 className=" xl:text-4xl lg:text-4xl text-2xl font-bold mx-auto xl:leading-[3] lg:leading-[3] leading-loose  font-sans tracking-wide mb-4">
                  IDES has two main components:
                </h1>
                <p className="leading-loose text-lg">
                  The <strong>Digital Economy Score (DES)</strong> indicates the level of
                  development of the digital economy and its main components: enabling policy
                  environment, digital infrastructure and payments, innovation ecosystem, and
                  customer skills. It also identifies the stage of development of the country’s
                  digital transformation, labelled as ‘Inception’, ‘Start-up’, ‘Expansion’ and
                  ‘Consolidation’
                </p>
                <br />
                <p className="leading-loose text-lg">
                  The{" "}
                  <strong title="Digital Inclusiveness Score">
                    Digital Inclusiveness Score (DIS)
                  </strong>{" "}
                  indicates the level of inclusion attained by key segments of the population
                  (customers) in the digital economy, such as women, youth, elderly, refugees,
                  migrants, micro-, small- and medium-sized enterprises, rural inhabitants and
                  people with disabilities. The DIS provides insight into the amount of effort made
                  by the public and private sectors to include marginalized people during the
                  development of the digital economy. Inclusion of women is a particular focus, the{" "}
                  <strong>Women Inclusiveness Score (WIS)</strong> is a subscore of the overall DIS
                  and captures key components that can drive the level of inclusion of women in the
                  digital economy.
                </p>
              </div>
              <img src={idesGraphics} alt="IDES Graphics breakdown" className="w-auto p-4" />
            </div>
          </section>
        </main>
        <Footer isFixed={false} />
      </div>
    </>
  );
};

export default Scorecard;
