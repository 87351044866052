import React from "react";
import { FiMail } from "react-icons/fi";
import { LinkedinShareButton, LinkedinIcon, FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon} from "react-share";
const CountryHeader = ({
  flagUrl,
  year,
  countryName,
  status_description,
  status,
  email,
  contact,
  id,
  shareUrl,
}) => {
  return (
    <>
      <div className="space-y-2 xl:flex xl:justify-between lg:flex lg:justify-between items-center">
        <div className="space-y-2">
          <div>
            <div className="flex items-center gap-4">
              <img className="w-24 h-24" alt={`Flag of ${countryName}`} src={flagUrl} />
              <div>
                <h1 className="text-2xl font-bold font-sans">{countryName}</h1>

                <div className="flex items-center space-x-1">
                  <p className="">{year}</p>
                  <span>/</span>
                  <p className=" uppercase font-semibold">{status}</p>
                </div>
                <p>{status_description}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="text-sm pb-2 space-y-1">
          <div className="flex items-center space-x-2 mt-4">
            <p className="font-medium">Share: </p>
            <ul className="flex space-x-2">
              <li>
                <LinkedinShareButton
                  source={`https://ides.uncdf.org/${year}/${id}`}
                  url={`https://ides.uncdf.org/${year}/${id}`}>
                  <LinkedinIcon size={24} />
                </LinkedinShareButton>
              </li>
              <li>
                <FacebookShareButton url={`https://ides.uncdf.org/${year}/${id}`}>
                  <FacebookIcon size={24} />
                </FacebookShareButton>
              </li>
              <li>
                <TwitterShareButton url={`https://ides.uncdf.org/${year}/${id}`}>
                  <TwitterIcon size={24} />
                </TwitterShareButton>
              </li>
            </ul>
          </div>
          <p>For more information about IDES in {countryName}, contact: </p>
          <a
            className="text-brand flex items-center w-max hover:text-gray-600 transition-colors"
            type="email"
            href={email}>
            <FiMail className="w-6 h-6 mr-1" />
            {contact}
          </a>
        </div>
      </div>
      <hr />
    </>
  );
};

export default CountryHeader;
