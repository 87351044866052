import React, {useId} from "react";
import ContactCard from "../components/Contacts/ContactCard";
import ContactGrid from "../components/Contacts/ContactGrid";
import ContactHeader from "../components/Contacts/ContactHeader";
import MetaDecorator from "../components/MetaDecorator";
import { useQuery } from "react-query";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import Error from "./Error";
import Footer from './../components/Footer';
const Contact = () => {

  const id = useId();

  const {
    data: contacts,
    isLoading,
    isFetching,
    isError,
  } = useQuery(
    "contacts",
    async () => {
      const res = await fetch(`/.netlify/functions/getContacts`);
      if (res.ok) return res.json();
      throw new Error("Something went wrong...");
    },
    {
      retry: 2,
      retryDelay: 2 * 1000, // 2 seconds
      refetchOnWindowFocus: false,
    },
  );

  if (isError) {
    return <Error />;
  }

  return (
    <>
      <MetaDecorator
        title={`Contact - Inclusive Digital Scorecard`}
        description="The Inclusive Digital Economy Scorecard (IDES) is a strategic performance and policy tool that has been developed to support countries in better understanding and monitoring the status of their digital transformation, with a view to helping them make it more inclusive. The IDES identifies the key market constraints hindering the development of an inclusive digital economy and helps governments to set the right priorities with public and private stakeholders, to foster accelerated development of a digital economy that leaves no one behind."
        imageUrl={"https://www.uncdf.org/download/file/8193?defaultFile=%2FDefaultImages%2FdefaultImage.png&thumbnail=False&cultureId=127&useLarge=true"}
        imageAlt={"UNCDF Logo"}
      />
      <main>
        <section className="container mx-auto my-12">
          <div className="xl:w-5/6 lg:5/6 md:5/6 w-full p-4 mx-auto space-y-12 text-brand">
            <div > 
              <h1 className="text-4xl font-bold leading-relaxed">Contact</h1>
              <p className=" leading-relaxed">
                If you want more information about IDES in your country or want to further discuss
                how IDES can be leveraged for more impact, please contact UNCDF colleagues based in
                countries.
              </p>
            </div>

            <div className="text-brand">
              <ContactHeader text="Global Contacts" />
              {isLoading || isFetching ? (
                <AiOutlineLoading3Quarters className="w-6 h-6 animate-spin font-bold text-brand" />
              ) : (
                <ContactGrid>
                  {contacts.map((contact, index) =>
                    contact.type === "Global" ? (
                      <ContactCard
                        name={contact.name}
                        contactPerson={contact.contactPerson}
                        email={contact.email}
                        key={`${id}-global-${index}`}
                      />
                    ) : null,
                  )}
                </ContactGrid>
              )}
            </div>
            <div className="text-brand">
              <ContactHeader text="Country" />
              {isLoading || isFetching ? (
                <AiOutlineLoading3Quarters className="w-6 h-6 animate-spin font-bold text-brand" />
              ) : (
                <ContactGrid>
                  {contacts.map((contact, index) =>
                    contact.type === "Country" ? (
                      <ContactCard
                        name={contact.name}
                        contactPerson={contact.contactPerson}
                        email={contact.email}
                        key={`${id}-country-${index}`}
                      />
                    ) : null,
                  )}
                </ContactGrid>
              )}
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default Contact;
