import React, { useContext } from "react";
import ReactTooltip from "react-tooltip";
import { YearContext } from "../context/YearContext";
import { colors } from "../utils";
import MapLegend from "./MapLegend";
const years = [2020, 2021, 2022, 2023];
const MapNav = ({ year, handleClick }) => {
  const { availableYears } =
    useContext(YearContext);

  return (
    <section className="absolute xl:bottom-40 lg:botttom-20 md:bottom-20  bottom-16 w-full">
      <div className="mb-4 w-96 text-sm font-semibold mx-auto mt-8 flex items-center justify-between text-white">
        <MapLegend
          color={colors.inception}
          name="Inception"
          type="inception"
        />
        <MapLegend
          color={colors.startUp}
          name="Start-Up"
          type="startup"
        />
        <MapLegend
          color={colors.expansion}
          name="Expansion"
          type="expansion"
        />
        <MapLegend
          color={colors.consolidation}
          name="Consolidation"
          type="consolidation"
        />
      </div>
      <div className="flex items-center justify-between bg-gray-800 px-8 py-4 rounded text-gray-50 w-96 mx-auto">
        {years.map((nYear) =>
          availableYears &&
          availableYears.includes(nYear) ? (
            <div key={`map-nav-${nYear}`}>
              <button
                onClick={handleClick}
                value={nYear}
                className={
                  year === nYear
                    ? `font-bold`
                    : ""
                }
                data-tip=""
                data-for={nYear}
              >
                {nYear}
              </button>
              <ReactTooltip
                type="dark"
                place="top"
                id={nYear + ""}
              />
            </div>
          ) : (
            <div key={`map-nav-${nYear}`}>
              <button
                value={nYear}
                data-tip="Data will be available in 2023"
                data-for={nYear + ""}
              >
                {nYear}
              </button>
              <ReactTooltip
                type="dark"
                place="top"
                id={nYear + ""}
              />
            </div>
          )
        )}
      </div>
    </section>
  );
};

export default MapNav;
