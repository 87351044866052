import React from "react";
import ExternalLink from "../ExternalLink";

const ReportCard = ({ title, src, link,country }) => {
  return (
    <div className=" p-4  text-white bg-[#5C6666]/5 flex flex-col justify-between rounded ring-brand/20 ring-1 hover:ring-offset-4">
      <img src={src} />
      <div>
        <ExternalLink
          href={link}
          title={`External Link`}
          customClass="text-lg font-bold hover:text-brand/80 transition-opacity cursor-pointer underline pt-2 text-brand">
          {title}
        </ExternalLink>
        <div className="text-white text-xs mt-4 p-2 shadow w-fit bg-brand">
          <h4>{country}</h4>
        </div>
      </div>
    </div>
  );
};

export default ReportCard;
