import React, {  useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { ReactComponent as LogoSVG } from "./../../assets/29.01.18-UNCDF-white-logo-tagline.svg";
import CountryMenu from "../CountryMenu";
import TopRowMenu from "./TopRowMenu";
import {AiFillCaretDown, AiFillCaretUp, AiOutlineMenu} from 'react-icons/ai'
import { useOnClickOutside } from "../../utils/hooks";
import MobileMenu from "./MobileMenu";

const Nav = () => {
  const expandRef = useRef(null);
  const aboutRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isToggled, setToggled] = useState(false);
  const [isMobileMenu, setMobileMenu] = useState(false);
  useOnClickOutside(expandRef, () => setIsOpen(false));
  useOnClickOutside(aboutRef, () => setToggled(false));
  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="w-full bg-[#2a3990] h-20 top-0 z-50 border-b border-b-brand-100/20 relative">
      <div className="px-8 flex justify-between text-white items-center h-full">
        <div className="flex space-x-4 items-center">
          <div>
            <Link to="/">
              <LogoSVG className="w-36 h-36" />
            </Link>
          </div>
          <div>
            <h1 className="text-xl font-semibold sm:hidden md:hidden lg:flex xl:flex hidden">
              <Link to="/">Inclusive Digital Economy Scorecard</Link>
            </h1>
          </div>
        </div>
        <ul className="hidden space-x-6 xs:hidden sm:hidden md:hidden lg:flex xl:flex font-sans">
          <li className="hover:text-brand-100">
            <NavLink to="/" className={(navData) => (navData.isActive ? "font-bold " : null)}>
              Dashboard
            </NavLink>
          </li>
          <li className="relative" ref={expandRef} onClick={() => handleClick()}>
            <button className="hover:text-brand-100 flex items-center">
              Countries <AiFillCaretDown className="ml-1" />
            </button>
            {isOpen ? <CountryMenu /> : ""}
          </li>

          <li className="hover:text-brand-100" ref={aboutRef}>
            <button className="flex items-center" onClick={() => setToggled((prev) => !prev)}>
              About
              {isToggled ? (
                <AiFillCaretUp className="ml-1" />
              ) : (
                <AiFillCaretDown className="ml-1" />
              )}
            </button>
            <TopRowMenu toggled={isToggled} setToggled={setToggled} />
          </li>
          <li className="hover:text-brand-100">
            <NavLink
              className={(navData) => (navData.isActive ? "font-bold " : null)}
              to="/reports">
              News
            </NavLink>
          </li>
          <li className="hover:text-brand-100">
            <NavLink
              to="/contact"
              className={(navData) => (navData.isActive ? "font-bold " : null)}>
              Contact
            </NavLink>
          </li>
        </ul>
        <button
          className="block space-x-6 xs:block sm:block md:block lg:hidden xl:hidden"
          onClick={() => setMobileMenu((prev) => !prev)}>
          <AiOutlineMenu className="w-6 h-6" />
        </button>
      </div>

      {isMobileMenu ? <MobileMenu isOpen={() => setMobileMenu(false)} /> : null}
    </nav>
  );
};

export default Nav;
