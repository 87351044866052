import React from 'react'
import { ResponsiveRadar } from '@nivo/radar';
const Radar = ({data, ...props}) => {
	const {info, total} = props;

const LabelComponent = ({ id, x, y, anchor }) => (
  <g transform={`translate(${x}, ${y})`}>
    <g transform={`translate(${anchor === "end" ? -30 : anchor === "middle" ? 0 :30}, -10)`}>
      <text
        y={12}
		x={0}
		textAnchor="middle"
		dominantBaseline="central"
        style={{
          fontSize: 16,
          fontWeight: "bold",
          fill: "#3a9896",
        }}>
        {id}
      </text>
    </g>
  </g>
);
	return (
    <div className="my-8 w-full h-auto">
      <div className="text-center">
        <h2 className=" font-bold text-lg" style={{ color: info.color }}>
          {info.name}: {total}%
        </h2>
      </div>
      <div className=" h-96 custom-text-color">
        <ResponsiveRadar
          data={data}
          keys={["score"]}
          indexBy="Block"
          maxValue={100}
          colors={info.color}
          margin={{ top: 50, right: 80, bottom: 40, left: 80 }}
          borderColor={{ from: "color" }}
          dotSize={5}
          dotColor={{ theme: "background" }}
          dotBorderWidth={2}
          motionConfig="wobbly"
          gridShape="linear"
          dotLabel={(e) => {
            return e.formattedValue + "% ";
          }}
          enableDotLabel={true}
          curve="cardinalClosed"
          gridLabel={LabelComponent}
          gridLabelOffset={20}
        />
      </div>
    </div>
  );
}

export default Radar
