import React from "react";
import { Helmet } from "react-helmet";

const MetaDecorator = ({
  title,
  description,
  imageUrl = "https://www.uncdf.org/download/file/8193?defaultFile=%2FDefaultImages%2FdefaultImage.png&thumbnail=False&cultureId=127&useLarge=true",
  imageAlt,
}) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name="description" content={description} />

      {/* <!-- Open Graph / Facebook --> */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={window.location.pathname + window.location.search} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:image:alt" content={imageAlt} />
      {/* <!-- Twitter --> */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta
        property="twitter:url"
        content={window.location.host + window.location.pathname + window.location.search}
      />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={imageUrl} />
      <meta property="twitter:image:alt" content={imageAlt} />
    </Helmet>
  );
};

export default MetaDecorator;
