import React from "react";
import { useNavigate } from "react-router";

const Dropdown = ({ list, year }) => {
  let navigate = useNavigate();
  const options = (listItem) => {
    return (
      <option
        value={listItem.id}
        key={listItem.id}
      >
        {listItem.value}
      </option>
    );
  };

  const getSelected = (e) => {
    // https://stackoverflow.com/questions/14976495/get-selected-option-text-with-javascript
    const select = e.target.value;
    navigate(`/${year}/${select}`);
  };

  return (
    <select
      onChange={getSelected}
      className="text-black p-2 rounded w-full"
    >
      <option>Select a country...</option>
      {list.map((listItem) => options(listItem))}
    </select>
  );
};

export default Dropdown;
