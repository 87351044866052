import React from "react";
import Plot from "react-plotly.js";
const NewPage = ({props}) => {



  const data = [
    {
      type: "sunburst",
      labels: [
        "Policy and Regulation",
        "Infrastructure",
        "Innovation",
        "Skills",
        "Government Promotion",
        "Policy Promotion",
        "DE Regulation",
        "ID Infrastructure",
        "Connectivity",
        "ICT Usage and Ownership",
        "Digital Payments",
        "Innovation Community Development",
        "Level of Skills",
        "Supporting Infrastructure",
        "Investment",
        "Basic Skills",
        "Digital Literacy",
        "Financial Literacy",
      ],
      parents: [
        "",
        "",
        "",
        "",
        "Policy and Regulation",
        "Policy and Regulation",
        "Policy and Regulation",
        "Infrastructure",
        "Infrastructure",
        "Infrastructure",
        "Infrastructure",
        "Innovation",
        "Innovation",
        "Innovation",
        "Innovation",
        "Skills",
        "Skills",
        "Skills",
      ],
      outsidetextfont: { size: 12, color: "#fff" },
      leaf: { opacity: 0.7 },
      marker: { line: { width: 2 } },
      textposition: "oustide",
      insidetextorientation: "radial",
      insidetextfont: { size: 12, color: "#000" },
      texttemplate: [
        "Policy &<br />Regulation",
        "Infrastructure",
        "Innovation",
		"Skills",
        "Government <br />Promotion",
        "Policy <br />Promotion",
        "Existing <br />Regulation",
        "ID <br />Infrastructure",
        "Connectivity",
        "ICT Usage",
        "Digital <br />Payments",
        "Community <br />Development",
        "Level of Skills",
        "Supporting <br />Environment",
        "Financing",
        "Basic Skills",
        "Digital Literacy",
        "Financial <br />Literacy",
      ],
	  values:props,
	  maxdepth:2,
	  hoverlabel: {
		namelength: 12
	  }
    },
  ];
  return (
    <div className="w-fit h-auto mx-auto">
      <Plot
        data={data}
        layout={{
          margin: { l: 15, r: 15, b: 15, t: 15 },
          width: 450,
          height: 450,
          sunburstcolorway: ["#e9b95a", "#2f5682", "#597347", "#de7648"],
          paper_bgcolor: "#f9fafb",
          hoverlabel: {
            namelength: 5,
          },
		  
		  
        }}
		
      />
    </div>
  );
};

export default NewPage;
