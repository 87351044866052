import React from "react";
import BreadCrumbs from "../../components/BreadCrumbs";
import ExternalLink from "../../components/ExternalLink";
import Footer from "../../components/Footer";
import AboutLayout from "../../components/Layout/AboutLayout";
import MetaDecorator from "../../components/MetaDecorator";
import Text from "../../components/Text";

const Methodology = () => {
  return (
    <>
      <MetaDecorator
        title="About the Methodology - Inclusive Digital Scorecard"
        description="The scores of the four building blocks are closely interlinked. An enabling policy and regulation environment should encourage the development of the infrastructure for a digital economy, spur innovation to accelerate digital transformation, and equip the country’s population with the skills to leverage the dividends of an inclusive digital transformation. As such, it is imperative that we see the Policy & Regulation score in conjunction with the scores for the other three blocks."
      />
      <div className="w-full h-full ">
        <main className="mx-auto">
          <AboutLayout>
            <BreadCrumbs path={"methodology"} />
            <h1
              className="xl:text-6xl lg:text-6xl text-4xl font-bold font-sans xl:leading-loose 
              lg:leading-loose leading-normal text-brand">
              About the Methodology
            </h1>
            <h2 className="text-xl font-bold text-brand">
              The IDES takes into account four building blocks relevant to the development of a
              digital economy:
            </h2>
            <ol className="space-y-4">
              <li>
                <Text className=" leading-loose text-lg">
                  1. In the <span className="text-brand font-bold">Policy & Regulation block</span>, the
                  scorecard captures the extent to which the government actively promotes the
                  development of an inclusive digital economy, as well as the policies and
                  regulations in place that support digital finance and the digital economy.{" "}
                </Text>
              </li>
              <li>
                <Text>
                  2. In the <span className="text-brand font-bold">Infrastructure block</span>, the scorecard quantifies the
                  level of development of the digital infrastructure (e.g. ID infrastructure, access
                  to electricity, phone ownership and network coverage) and the status of the
                  digital payment ecosystem, including the level of interoperability and the
                  openness of the digital infrastructure for third-party players.
                </Text>
              </li>
              <li>
                <Text>
                  3. In the <span className="text-brand font-bold">Innovation block</span>, the scorecard measures the status of
                  a country’s innovation ecosystem. Key elements are the following: the level of
                  development and the synergies within the innovation community; the level of skills
                  in the ecosystem; the presence of supporting infrastructure; and the availability
                  of financing for innovation.
                </Text>
              </li>
              <li>
                <Text>
                  4. In the <span className="text-brand font-bold">Skills block</span>, the scorecard tracks the active
                  participation of the public and private sectors in digital and financial skills
                  development, as well as the usage of digital channels for relevant skills
                  development.
                </Text>
              </li>
            </ol>
          </AboutLayout>
          <AboutLayout withBackround={true}>
            <Text>
              The first two ‘blocks’, Policy & Regulation together with Infrastructure, form the
              foundation of an inclusive digital economy. At UNCDF, this foundation is referred to
              as the ‘digital rails’. If the digital rails are properly developed, they open avenues
              for innovation by third-party players and for the use of technology to aid people’s
              skill development.
            </Text>
            <Text>
              Since UNCDF’s key focus is to support digital economies that leave no one behind, the
              inclusiveness of the digital economy is measured across the four building blocks,
              based on a supply-side qualitative assessment of the efforts made by the public and
              private sectors to include key segments of the population in the expansion of the
              digital economy (see description of DIS above). As a subscore of the DIS, the Women’s
              Inclusiveness Score (WIS) additionally relies on a set of quantitative indicators on
              relative gender gaps in the four building blocks of IDES.
            </Text>
            <Text>
              The list of indicators used for the scorecard can be found{" "}
              <ExternalLink
                href="https://www.uncdf.org/Download/AdminFileWithFilename?id=15541&cultureId=127&filename=24092021-ides2020indicatoroverviewxlsx"
                customClass="underline font-bold hover:text-white/60"
                title="Downloadable link">
                here.
              </ExternalLink>
            </Text>
          </AboutLayout>

          <AboutLayout>
            <Text>
              The scores of the four building blocks are closely interlinked. An enabling policy and
              regulation environment should encourage the development of the infrastructure for a
              digital economy, spur innovation to accelerate digital transformation, and equip the
              country’s population with the skills to leverage the dividends of an inclusive digital
              transformation. As such, it is imperative that we see the Policy & Regulation score in
              conjunction with the scores for the other three blocks.
            </Text>
            <Text>
              As an example, if a country has scored relatively high in Policy & Regulation,
              compared with the other three blocks, this may indicate that the government recognizes
              the importance of the digital transformation and has placed it high on their policy
              agenda. However, if the same country has scored relatively low in Infrastructure,
              Innovation and Skills, this indicates there is a potential opportunity for the
              government to improve its policies and regulations to incentivize the necessary
              investments in relevant infrastructure and innovation. The government may need to
              reassess its approach to policy implementation in these areas and also work on
              building the population’s digital skills.
            </Text>
            <Text>
              IDES is designed to help national partners bring focus to key priorities and to track
              their progress. Its primary purpose is as a policy tool, so UNCDF works with national
              partners to implement it in a way that is locally relevant and pragmatic to use. As
              such, the implementation of IDES also involves the incorporation of high quality local
              data sources where available, in consultation with national partners. Given possible
              variations in local reporting standards, IDES’ main focus is hence less on
              country-by-country comparisons but primarily on monitoring national developments in
              inclusive digital economies over time.
            </Text>
          </AboutLayout>
        </main>
        <Footer isFixed={false} />
      </div>
    </>
  );
};

export default Methodology;
