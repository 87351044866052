import React from "react";
import { Link } from "react-router-dom";

const TopRowMenu = ({ toggled, setToggled }) => {
  if (toggled) {
    return (
      <nav className="w-full grid place-items-center bg-brand/80 xs:hidden sm:hidden md:hidden lg:flex xl:flex absolute left-0 top-20">
        <ul className="flex mx-auto justify-between p-4 text-white space-x-12 font-semibold">
          <Link to="/about/scorecard" onClick={() => setToggled(false)}>
            <li>Scorecard</li>
          </Link>
          <li>
            <Link to="/about/methodology" onClick={() => setToggled(false)}>
              Methodology
            </Link>
          </li>
          <li>
            <Link to="/about/market-development-stages" onClick={() => setToggled(false)}>
              Market Development Stages
            </Link>
          </li>
        </ul>
      </nav>
    );
  }
};

export default TopRowMenu;
