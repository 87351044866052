import React from 'react'

const ExternalLink = ({href,children, customClass ="", title=""}) => {
  return (
    <a href={href} referrerPolicy="no-referrer" className={`w-fit mx-auto ${customClass}`} title={title}>
      {children}
    </a>
  );
}

export default ExternalLink