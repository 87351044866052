import React from 'react'

const ContactCard = ({name, contactPerson, email}) => {
  return (
    <div className="border p-4 h-40 flex flex-col justify-between shadow rounded bg-[#5C6666]/5 text-brand">
      <h3 className="text-2xl font-extrabold ">{name}</h3>
      <div>
        <h4 className="font-semibold">{contactPerson}</h4>
        <a
          href={`mailto:${email}`}
          className="underline text-xs hover:text-brand transition-opacity">
          {email}
        </a>
      </div>
    </div>
  );
}

export default ContactCard