import React, {useId} from "react";
import { useQuery } from "react-query";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import ReportsLayout from "../components/Layout/ReportsLayout";
import ReportCard from "../components/Cards/ReportCard";
import Error from "./Error";
import MetaDecorator from "../components/MetaDecorator";

const Reports = () => {
  const id = useId();
  const {
    data: reports,
    isError,
    isLoading,
    isFetching,
    isFetched,
  } = useQuery(
    "reports",
    async () => {
      const req = await fetch("/.netlify/functions/getReports");
      if (req.ok) return req.json();
      throw new Error("Something went wrong...");
    },
    {
      retry: 2,
      retryDelay: 1 * 1000, // 1 second
      refetchOnWindowFocus: false,
    },
  );
  if (isError) {
    return <Error />;
  }

  return (
    <>
      <MetaDecorator
        title={"Reports - Inclusive Digital Economy Scorecard"}
        description="The Inclusive Digital Economy Scorecard (IDES) is a strategic performance and policy tool that has been developed to support countries in better understanding and monitoring the status of their digital transformation, with a view to helping them make it more inclusive. The IDES identifies the key market constraints hindering the development of an inclusive digital economy and helps governments to set the right priorities with public and private stakeholders, to foster accelerated development of a digital economy that leaves no one behind."
        imageUrl={"https://www.uncdf.org/download/file/8193?defaultFile=%2FDefaultImages%2FdefaultImage.png&thumbnail=False&cultureId=127&useLarge=true"}
        imageAlt={"UNCDF Logo"}
      />
      <main>
        <section className="container mx-auto my-12 p-4">
          <h1 className="text-2xl font-bold text-brand">News and Reports</h1>

          <div className="mt-4">
            {isLoading || (isFetching && !isFetched) ? (
              <AiOutlineLoading3Quarters className="w-6 h-6 animate-spin font-bold text-brand" />
            ) : (
              <ReportsLayout>
                {reports.map((report) => (
                  <ReportCard
                    title={report.title}
                    src={report.attachment}
                    link={report.link}
                    country={report.country}
                    key={`${id}-${report.country}`}
                  />
                ))}
              </ReportsLayout>
            )}
          </div>
          <div className="text-brand/80 font-semibold mt-12 ">
            {isFetched ? (
              <p className="ml-auto w-fit">{`${reports.length} results found`}</p>
            ) : null}
          </div>
        </section>
      </main>
    </>
  );
};

export default Reports;
