import React from "react";
import ExternalLink from "../components/ExternalLink";
import MetaDecorator from './../components/MetaDecorator'
const Error = () => {
  return (
    <>
      <MetaDecorator
        title={`Error - Inclusive Digital Scorecard`}
        description="The Inclusive Digital Economy Scorecard (IDES) is a strategic performance and policy tool that has been developed to support countries in better understanding and monitoring the status of their digital transformation, with a view to helping them make it more inclusive. The IDES identifies the key market constraints hindering the development of an inclusive digital economy and helps governments to set the right priorities with public and private stakeholders, to foster accelerated development of a digital economy that leaves no one behind."
        imageUrl={"https://www.uncdf.org/download/file/8193?defaultFile=%2FDefaultImages%2FdefaultImage.png&thumbnail=False&cultureId=127&useLarge=true"}
        imageAlt={"UNCDF Logo"}
      />

      <main className="w-full h-[calc(100vh-80px)] bg-brand">
        <section className="w-full h-full grid place-items-center">
          <div className="text-white text-center space-y-4">
            <h1 className="text-3xl">Apologies, something went wrong.</h1>
            <p className="font-bold">Please try to refresh the page or go to the <a href="https://ides.uncdf.org">homepage</a>.</p>
            <p>
              If problem persists please contact:{" "}
              <ExternalLink
                title="info@uncdf.org"
                href="mailto:info@uncdf.org"
                customClass="underline font-bold">
                info@uncdf.org
              </ExternalLink>
            </p>
          </div>
        </section>
      </main>
    </>
  );
};

export default Error;
