import React from "react";

const Card = ({heading, children, icon, alt}) => {
  return (
    <div className="w-full h-auto rounded flex justify-center p-6 items-center gap-4 shadow-xl border border-white/10 bg-[#29388e] text-white">
      <div>
       {icon}
      </div>
      <div className="max-w-96">
        <h1 className="text-xl font-bold leading-relaxed font-sans">{heading}</h1>
        {children}
      </div>
    </div>
  );
};

export default Card;
