import React from 'react'
import { Link } from "react-router-dom";
const BreadCrumbs = ({path}) => {

  return (
    <ul className="flex space-x-2 mb-4 border-b pb-2 border-gray-200 flex-wrap text-brand">
      <li>
        About
      </li>
      <li>/</li>
      <li className={`hover:text-brand ${path === "scorecard" ? "font-bold" : null}`}>
        <Link to="/about/scorecard">Scorecard</Link>
      </li>
      <li>/</li>
      <li className={`hover:text-brand ${path === "methodology" ? "font-bold" : null}`}>
        <Link to="/about/methodology" className="hover:text-brand">
          Methodology
        </Link>
      </li>
      <li>/</li>
      <li className={`hover:text-brand ${path === "mds" ? "font-bold" : null}`}>
        <Link to="/about/market-development-stages">Market Development Stages</Link>
      </li>
    </ul>
  );
}

export default BreadCrumbs