import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import React, {
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import ReactDOM from "react-dom";
import { useQuery } from "react-query";
import { useNavigate } from "react-router";
import MapMenu from "../MapMenu";
import { YearContext } from "./../context/YearContext";
import Error from "./../pages/Error";
import { getColor } from "./../utils";
import Footer from "./Footer";
import Loading from "./Loading";
import MapNav from "./MapNav";
import MetaDecorator from "./MetaDecorator";
import Tooltip from "./Tooltip";

mapboxgl.accessToken =
  process.env.REACT_APP_MAPBOX_SECRET ||
  process.env.MAPBOX_SECRET;

const Map = () => {
  const customTooltip = useRef(
    new mapboxgl.Popup({
      closeOnMove: true,
      closeButton: false,
      closeOnClick: true,
      offset: 35,
    })
  );
  const {
    year,
    setYear,
    filter,
    setFilter,
    setCountries,
  } = useContext(YearContext);
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(50.747556);
  const [lat, setLat] = useState(-20.259);
  const [zoom, setZoom] = useState(2);
  let navigate = useNavigate();
  let dropdownList = null;
  const {
    data,
    isLoading,
    error,
    isError,
    refetch,
  } = useQuery(
    `country-${year}`,
    async () => {
      const res = await fetch(
        `/.netlify/functions/getCountries?year=${year}`
      );
      if (res.ok) {
        return res.json();
      }
      throw new Error("Something went wrong...");
    },
    {
      select: (data) => {
        const countries = data.map((country) => ({
          id: country.id,
          country_name: country.fields.Country,
          country_main_scores: {
            digital_economy_score: Math.floor(
              country.fields[
                "Digital Economy Score"
              ] * 100
            ),
            digital_inclusiveness_score:
              Math.floor(
                country.fields[
                  "Digital Inclusiveness Score"
                ] * 100
              ),
            gender_inclusiveness_score:
              Math.floor(
                country.fields[
                  "Gender Inclusiveness Score"
                ] * 100
              ),
          },
          country_geo: country.fields.geo[0].url,
          country_status: country.fields.Status,
          country_flag:
            country.fields.Flag[0].url,
          country_code:
            country.fields["CountryCode"],
        }));

        dropdownList = countries.map(
          (country) => {
            return {
              id: country.country_code,
              value: country.country_name,
            };
          }
        );

        return countries;
      },
      notifyOnChangeProps: ["data"],
      refetchOnWindowFocus: false,
    }
  );

  const handleClick = (e) => {
    setYear(Number(e.target.value));
    setFilter("all");
    refetch();
  };

  useEffect(() => {
    let currentMap = map.current;
    if (map.current) return; // initialize map only once
    if (!isLoading || !isError)
      currentMap = new mapboxgl.Map({
        container: mapContainer.current,
        style:
          "mapbox://styles/ezukutu/cl4323npn000h14p3yyapzo28",
        center: [lng, lat],
        zoom: zoom,
        dragPan: true,
        pitchWithRotate: false,
        dragRotate: false,
        trackResize: true,
        attributionControl: false,
      });
    currentMap.addControl(
      new mapboxgl.NavigationControl()
    );
    currentMap.addControl(
      new mapboxgl.AttributionControl({
        compact: false,
        offset: 150,
      }),
      "bottom-right"
    );
    currentMap.on("mouseover", () => {
      currentMap.getCanvas().style.cursor =
        "pointer";
    });
    currentMap.on("load", () => {
      const layers = currentMap.getStyle().layers;
      let firstSymbolId;
      for (const layer of layers) {
        if (layer.type === "symbol") {
          firstSymbolId = layer.id;
          break;
        }
      }

      if (data) {
        setCountries(data);
        switch (filter) {
          case "all":
            for (
              let i = 0;
              i < data.length;
              i++
            ) {
              currentMap.addSource(
                data[i].country_name,
                {
                  type: "geojson",
                  data: data[i].country_geo,
                }
              );
              currentMap.addLayer(
                {
                  id: `${data[i].country_name}-fill`,
                  type: "fill",
                  source: data[i].country_name,
                  paint: {
                    "fill-color": getColor(
                      data[i].country_status
                    ),
                    "fill-opacity": 0.7,
                  },
                },
                firstSymbolId
              );
              currentMap.addLayer({
                id: `${data[i].country_name}-outline`,
                type: "line",
                source: data[i].country_name,
                paint: {
                  "line-color": "#fff",
                  "line-width": 1,
                },
              });

              currentMap.on(
                "mouseenter",
                `${data[i].country_name}-fill`,
                (e) => {
                  const tooltipNode =
                    document.createElement("div");
                  ReactDOM.render(
                    <Tooltip
                      countryName={
                        data[i].country_name
                      }
                      countryFlag={
                        data[i].country_flag
                      }
                      data={
                        data[i]
                          .country_main_scores
                      }
                      year={year}
                      status={
                        data[i].country_status
                      }
                    />,
                    tooltipNode
                  );

                  customTooltip.current
                    .setLngLat(e.lngLat)
                    .setDOMContent(tooltipNode)
                    .addTo(currentMap);
                }
              );

              currentMap.on(
                "click",
                `${data[i].country_name}-fill`,
                (e) => {
                  navigate(
                    `/${year}/${data[i].country_code}`
                  );
                }
              );
            }
            break;
          case "inception":
            for (
              let i = 0;
              i < data.length;
              i++
            ) {
              if (
                data[i].country_status ===
                "Inception"
              ) {
                currentMap.addSource(
                  data[i].country_name,
                  {
                    type: "geojson",
                    data: data[i].country_geo,
                  }
                );
                currentMap.addLayer({
                  id: `${data[i].country_name}-fill`,
                  type: "fill",
                  source: data[i].country_name,
                  paint: {
                    "fill-color": getColor(
                      data[i].country_status
                    ),
                    "fill-opacity": 0.8,
                  },
                });
                currentMap.addLayer({
                  id: `${data[i].country_name}-outline`,
                  type: "line",
                  source: data[i].country_name,
                  paint: {
                    "line-color": "#fff",
                    "line-width": 0.5,
                  },
                });

                currentMap.on(
                  "mouseenter",
                  `${data[i].country_name}-fill`,
                  (e) => {
                    const tooltipNode =
                      document.createElement(
                        "div"
                      );
                    ReactDOM.render(
                      <Tooltip
                        countryName={
                          data[i].country_name
                        }
                        countryFlag={
                          data[i].country_flag
                        }
                        data={
                          data[i]
                            .country_main_scores
                        }
                        year={year}
                        status={
                          data[i].country_status
                        }
                      />,
                      tooltipNode
                    );

                    customTooltip.current
                      .setLngLat(e.lngLat)
                      .setDOMContent(tooltipNode)
                      .addTo(currentMap);
                  }
                );

                currentMap.on(
                  "click",
                  `${data[i].country_name}-fill`,
                  (e) => {
                    navigate(
                      `/${year}/${data[i].country_code}`
                    );
                  }
                );
              }
            }
            break;
          case "expansion":
            for (
              let i = 0;
              i < data.length;
              i++
            ) {
              if (
                data[i].country_status ===
                "Expansion"
              ) {
                currentMap.addSource(
                  data[i].country_name,
                  {
                    type: "geojson",
                    data: data[i].country_geo,
                  }
                );
                currentMap.addLayer(
                  {
                    id: `${data[i].country_name}-fill`,
                    type: "fill",
                    source: data[i].country_name,
                    paint: {
                      "fill-color": getColor(
                        data[i].country_status
                      ),
                      "fill-opacity": 0.8,
                    },
                  },
                  firstSymbolId
                );
                currentMap.addLayer({
                  id: `${data[i].country_name}-outline`,
                  type: "line",
                  source: data[i].country_name,
                  paint: {
                    "line-color": "#fff",
                    "line-width": 0.5,
                  },
                });

                currentMap.on(
                  "mouseenter",
                  `${data[i].country_name}-fill`,
                  (e) => {
                    const tooltipNode =
                      document.createElement(
                        "div"
                      );
                    ReactDOM.render(
                      <Tooltip
                        countryName={
                          data[i].country_name
                        }
                        countryFlag={
                          data[i].country_flag
                        }
                        data={
                          data[i]
                            .country_main_scores
                        }
                        year={year}
                        status={
                          data[i].country_status
                        }
                      />,
                      tooltipNode
                    );

                    customTooltip.current
                      .setLngLat(e.lngLat)
                      .setDOMContent(tooltipNode)
                      .addTo(currentMap);
                  }
                );

                currentMap.on(
                  "click",
                  `${data[i].country_name}-fill`,
                  (e) => {
                    navigate(
                      `/${year}/${data[i].country_code}`
                    );
                  }
                );
              }
            }
            break;
          case "startup":
            for (
              let i = 0;
              i < data.length;
              i++
            ) {
              if (
                data[i].country_status ===
                "Start-up"
              ) {
                currentMap.addSource(
                  data[i].country_name,
                  {
                    type: "geojson",
                    data: data[i].country_geo,
                  }
                );
                currentMap.addLayer(
                  {
                    id: `${data[i].country_name}-fill`,
                    type: "fill",
                    source: data[i].country_name,
                    paint: {
                      "fill-color": getColor(
                        data[i].country_status
                      ),
                      "fill-opacity": 0.8,
                    },
                  },
                  firstSymbolId
                );
                currentMap.addLayer({
                  id: `${data[i].country_name}-outline`,
                  type: "line",
                  source: data[i].country_name,
                  paint: {
                    "line-color": "#fff",
                    "line-width": 0.5,
                  },
                });

                currentMap.on(
                  "mouseenter",
                  `${data[i].country_name}-fill`,
                  (e) => {
                    const tooltipNode =
                      document.createElement(
                        "div"
                      );
                    ReactDOM.render(
                      <Tooltip
                        countryName={
                          data[i].country_name
                        }
                        countryFlag={
                          data[i].country_flag
                        }
                        data={
                          data[i]
                            .country_main_scores
                        }
                        year={year}
                        status={
                          data[i].country_status
                        }
                      />,
                      tooltipNode
                    );

                    customTooltip.current
                      .setLngLat(e.lngLat)
                      .setDOMContent(tooltipNode)
                      .addTo(currentMap);
                  }
                );

                currentMap.on(
                  "click",
                  `${data[i].country_name}-fill`,
                  (e) => {
                    navigate(
                      `/${year}/${data[i].country_code}`
                    );
                  }
                );
              }
            }
            break;
          case "consolidation":
            for (
              let i = 0;
              i < data.length;
              i++
            ) {
              if (
                data[i].country_status ===
                "Consolidation"
              ) {
                currentMap.addSource(
                  data[i].country_name,
                  {
                    type: "geojson",
                    data: data[i].country_geo,
                  }
                );
                currentMap.addLayer(
                  {
                    id: `${data[i].country_name}-fill`,
                    type: "fill",
                    source: data[i].country_name,
                    paint: {
                      "fill-color": getColor(
                        data[i].country_status
                      ),
                      "fill-opacity": 0.8,
                    },
                  },
                  firstSymbolId
                );
                currentMap.addLayer({
                  id: `${data[i].country_name}-outline`,
                  type: "line",
                  source: data[i].country_name,
                  paint: {
                    "line-color": "#fff",
                    "line-width": 0.5,
                  },
                });

                currentMap.on(
                  "mouseenter",
                  `${data[i].country_name}-fill`,
                  (e) => {
                    const tooltipNode =
                      document.createElement(
                        "div"
                      );
                    ReactDOM.render(
                      <Tooltip
                        countryName={
                          data[i].country_name
                        }
                        countryFlag={
                          data[i].country_flag
                        }
                        data={
                          data[i]
                            .country_main_scores
                        }
                        year={year}
                        status={
                          data[i].country_status
                        }
                      />,
                      tooltipNode
                    );

                    customTooltip.current
                      .setLngLat(e.lngLat)
                      .setDOMContent(tooltipNode)
                      .addTo(currentMap);
                  }
                );

                currentMap.on(
                  "click",
                  `${data[i].country_name}-fill`,
                  (e) => {
                    navigate(
                      `/${year}/${data[i].country_code}`
                    );
                  }
                );
              }
            }
            break;
          default:
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, year, data]);

  if (isError) {
    return <Error />;
  }

  return (
    <>
      <MetaDecorator
        title={`Inclusive Digital Economy Scorecard`}
        description="The Inclusive Digital Economy Scorecard (IDES) is a strategic performance and policy tool that has been developed to support countries in better understanding and monitoring the status of their digital transformation, with a view to helping them make it more inclusive. The IDES identifies the key market constraints hindering the development of an inclusive digital economy and helps governments to set the right priorities with public and private stakeholders, to foster accelerated development of a digital economy that leaves no one behind."
        imageUrl={
          "https://www.uncdf.org/download/file/8193?defaultFile=%2FDefaultImages%2FdefaultImage.png&thumbnail=False&cultureId=127&useLarge=true"
        }
        imageAlt={"UNCDF Logo"}
      />
      <main className="w-full h-[calc(100vh-79px)] relative">
        {isError ? (
          <h1>
            Something went wrong...{" "}
            {error.message}{" "}
          </h1>
        ) : (
          ""
        )}
        <section
          className="w-full h-full cursor-pointer"
          ref={mapContainer}
          id="map-container"
        ></section>
        {isLoading ? (
          <Loading />
        ) : (
          <MapMenu
            dropdownList={dropdownList}
            year={year}
          />
        )}
        <MapNav
          year={year}
          handleClick={handleClick}
        />
        <Footer isFixed={true} />
      </main>
    </>
  );
};

export default Map;
