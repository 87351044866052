import React from 'react'

const CardContainer = ({children}) => {
  return (
    <div className="grid grid-cols-[repeat(auto-fit,minmax(350px,1fr))] gap-12 p-6">
      {children}
    </div>
  );
}

export default CardContainer;