import React from 'react'
import Dropdown from './components/Dropdown';
import { Link } from 'react-router-dom';
const MapMenu = ({dropdownList, year}) => {
  return (
    <aside className="absolute top-5 left-2 w-64 sm:hidden xs:hidden md:hidden xl:block lg:block hidden">
      <div className="my-4 space-y-2">
        <div>
          <Link to="about/scorecard">
            <button className="px-6 py-2 bg-brand w-full text-gray-50 rounded hover:bg-brand-400">
              Scorecard
            </button>
          </Link>
        </div>
        <div>
          <Link to="about/methodology">
            <button className="px-6 py-2 bg-brand w-full text-gray-50 rounded hover:bg-brand-400">
              Methodology
            </button>
          </Link>
        </div>
        <div>
          <Link to="about/market-development-stages">
            <button className="px-6 py-2 bg-brand w-full text-gray-50 rounded hover:bg-brand-400">
              Market Development Stages
            </button>
          </Link>
        </div>
      </div>
      <div className="bg-brand h-auto w-full">
        <div className="p-4">
          <div className="text-white mb-4 space-y-2">
            <h2 className="text-lg font-medium">Inclusive Digital Economy Scorecard (IDES)</h2>
            <p className="text-sm">A strategic tool to measure and track the level of development of an inclusive digital economy
            </p>
          </div>
          <Dropdown list={dropdownList} year={year} />
        </div>
      </div>
    </aside>
  );
}

export default MapMenu
