export const get_percent = (rawNum) => {
  const num = rawNum.toFixed(2);
  return Math.floor(num * 100);
};
export const colors = {
  inception: "#CCCCFF",
  startUp: "#9999FF",
  expansion: "#6666FF",
  consolidation: "#3333CC",
};
export const getColor = (status) => {
  let color;
  switch (status) {
    case "Inception":
      color = colors.inception;
      break;
    case "Start-up":
      color = colors.startUp;
      break;
    case "Expansion":
      color = colors.expansion;
      break;
    case "Consolidation":
      color = colors.consolidation;
      break;
    default:
      break;
  }
  return color;
};

export const formatText = (text) => {
  let formattedText = text.split(' ');
  formattedText = formattedText.join('-').toLowerCase();
  return formattedText;
}