import React from "react";
import BreadCrumbs from "../../components/BreadCrumbs";
import Footer from "../../components/Footer";
import AboutLayout from "../../components/Layout/AboutLayout";
import MetaDecorator from "../../components/MetaDecorator";
import Text from "../../components/Text";
import AboutCard from "./../../components/Cards/AboutCard";

const MDS = () => {
  return (
    <>
      <MetaDecorator
        title="About the Market Development Stages - Inclusive Digital Scorecard"
        description="The Digital Economy Score (DES) provides a framework to better understand the level of development of a digital economy with 4 main development stages (Inception, Start-up, Expansion, Consolidation), and also highlights the priorities relevant to the four building blocks: Policy & Regulation, Infrastructure, Innovation and Skills."
      />
      <div className="w-full h-full ">
        <main className="mx-auto">
          <AboutLayout withBackround={false}>
            <BreadCrumbs path={"mds"} />
            <h1
              className="xl:text-6xl lg:text-6xl text-4xl font-bold font-sans xl:leading-loose 
              lg:leading-loose leading-normal text-brand">
              About the Market Development Stages
            </h1>
            <h2 className="text-lg leading-loose">
              The <span className="text-brand font-bold">Digital Economy Score (DES)</span> provides
              a framework to better understand the level of development of a digital economy with{" "}
              <span className="text-brand font-bold">
                4 main development stages (Inception, Start-up, Expansion, Consolidation)
              </span>
              , and also highlights the priorities relevant to the four building blocks: Policy &
              Regulation, Infrastructure, Innovation and Skills.
            </h2>
          </AboutLayout>
          <AboutLayout withBackround={true}>
            <h2 className="text-2xl font-bold capitalize">The four market development stages</h2>
          </AboutLayout>

          <AboutLayout>
            <h3 className="text-2xl font-bold leading-relaxed text-[#29388e]">
              Inception stage (DES up to 24 percent)
            </h3>
            <Text>
              The country does not have the foundational digital rails in place (policy and
              regulation, ID infrastructure, phone ownership, agent network and digital financial
              services) to allow the development of digital services beyond voice and Internet.
              There are no providers offering mass-market digital services beyond telecommunication
              services. Citizens cannot rely on technology to access and use services in finance or
              other areas.
            </Text>
            <AboutCard customClass="mt-4">
              <p className="leading-normal text-lg">
                Moving beyond this stage requires as a priority for digital finance providers to
                find the right balance of active customers and active, accessible agents to serve
                them and reach profitability. Some entrepreneurs see opportunities to leverage
                digital finance and pilot digital services in different domains (e.g. finance,
                agriculture, energy, health, education, ecommerce). Some markets remain stuck in
                this stage for many years until providers address all of the service issues that
                impede customer adoption and agent activity.
              </p>
            </AboutCard>
          </AboutLayout>
          <AboutLayout withBackround={false}>
            <h3 className="text-2xl font-bold leading-relaxed ">
              Start-up stage (DES between 25 percent and 49 percent)
            </h3>
            <Text>
              The country has the relevant foundational digital rails (see above) in place for
              citizens to access and use some basic digital services, mainly in the payment/finance
              sector. Several providers are offering mass-market digital financial services reaching
              the unbanked. Innovation is still in its infancy, with some incubators and starts-ups
              launching services and with limited use cases leveraging payment services.
            </Text>
            <AboutCard customClass="mt-4">
              <p className="leading-normal text-lg">
                Moving beyond this stage requires as a priority for digital finance providers to
                find the right balance of active customers and active, accessible agents to serve
                them and reach profitability. Some entrepreneurs see opportunities to leverage
                digital finance and pilot digital services in different domains (e.g. finance,
                agriculture, energy, health, education, ecommerce). Some markets remain stuck in
                this stage for many years until providers address all of the service issues that
                impede customer adoption and agent activity.
              </p>
            </AboutCard>
          </AboutLayout>

          <AboutLayout>
            <h3 className="text-2xl font-bold leading-relaxed text-[#29388e]">
              Expansion stage (DES between 50 percent and 74 percent)
            </h3>
            <Text>
              With the digital rails in place and digital payment systems becoming widely available
              and actively used, the innovation ecosystem has started to develop, with various new
              partnerships and services in a range of domains (e.g. finance, agriculture, energy,
              health, education, ecommerce). Citizens have started to gain access and use a series
              of new services on the market beyond digital payment services. Numerous players
              (providers, fintech, start-ups, entrepreneurs, incubators, etc.) are developing in the
              market.
            </Text>
            <AboutCard customClass="mt-4">
              <p className="leading-normal text-lg">
                Moving beyond this stage requires strong prioritization and support for innovation
                from government and private sector actors, using the right incentives to foster
                growth of start-ups, helping them to source the right expertise for their teams, and
                providing them with access to financing along all stages of development.
              </p>
            </AboutCard>
          </AboutLayout>

          <AboutLayout withBackround={false}>
            <h3 className="text-2xl font-bold leading-relaxed ">
              Consolidation stage (DES of 75 percent or higher)
            </h3>
            <Text>
              Citizens benefits from a large offering of digital services in various domains that
              are accessible and easy to use. They enjoy a choice of providers. In this stage,
              providers have moved beyond a focus on access and usage; now they are competing to
              keep their clients and focusing their attention on how to add value and increase the
              impact of their services for their clients.
            </Text>
            <AboutCard customClass="mt-4">
              <p className="leading-normal text-lg">
                During this stage, citizens have access to, actively use and harvest the benefits of
                the digital economy in various aspects of their daily lives.
              </p>
            </AboutCard>
          </AboutLayout>
        </main>
        <Footer isFixed={false} />
      </div>
    </>
  );
};

export default MDS;
