import {
  useLocation,
  useNavigate,
} from "react-router-dom";

const YearDropdown = ({
  years,
  setYear,
  activeYear,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleChange = (e) => {
    const year = Number(e.currentTarget.value);
    const path = location.pathname.split("/");
    console.log(path);
    if (location.pathname) {
      setYear(year);
      navigate(`/${year}/${path[2]}`);
    }
  };

  return (
    <div className="flex items-center gap-2">
      <h3 className="text-lg font-medium">
        Year:{" "}
      </h3>
      <select
        className="p-2 border rounded shadow text-lg font-medium"
        onChange={handleChange}
        defaultValue={activeYear}
      >
        {years.map((year) => (
          <option
            id={year}
            key={year}
            value={year}
          >
            {year}
          </option>
        ))}
      </select>
    </div>
  );
};

export default YearDropdown;
