import { useContext, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router";
import BarStacked from "../components/Charts/BarStacked";
import Radar from "../components/Charts/Radar";
import Radial from "../components/Charts/Radial";
import CountryHeader from "../components/CountryPage/CountryHeader";
import YearDropdown from "../components/CountryPage/YearDropdown";
import Loading from "../components/Loading";
import MetaDecorator from "../components/MetaDecorator";
import { YearContext } from "../context/YearContext";
import { get_percent } from "../utils";
import Footer from "./../components/Footer";
import Error from "./../pages/Error";
import NewPage from "./NewPage";

const Country = () => {
  const param = useParams();
  const id = param.id;
  const [year, setYear] = useState(param.year);
  const { availableYears } =
    useContext(YearContext);

  const {
    data: country,
    isLoading,
    isError,
    isFetching,
    isFetched,
  } = useQuery(
    [param.country, year],
    async () => {
      const res = await fetch(
        `/.netlify/functions/getCountry?id=${param.country}&year=${year}`
      );
      if (res.ok) return res.json();
      throw new Error("Something went wrong...");
    },
    {
      retry: 2,
      retryDelay: 2 * 1000, // 2 seconds
      staleTime: 60 * 1000, // 1 minute
      refetchOnWindowFocus: false,
    }
  );
  if (isLoading || isFetching) {
    return <Loading />;
  }

  if (isError) {
    return <Error />;
  }

  const data = [
    {
      id: "Women Inclusiveness Score",
      data: [
        {
          x: "Women Inclusiveness Score",
          y: get_percent(
            country.fields[
              "Gender Inclusiveness Score"
            ]
          ),
        },
      ],
    },

    {
      id: "Digital Inclusiveness Score",
      data: [
        {
          x: "Digital Inclusiveness",
          y: get_percent(
            country.fields[
              "Digital Inclusiveness Score"
            ]
          ),
        },
      ],
    },
    {
      id: "Digital Economy Score",
      data: [
        {
          x: "Digital Economy Score",
          y: get_percent(
            country.fields[
              "Digital Economy Score"
            ]
          ),
        },
      ],
    },
  ];

  // Policy and Regulation
  const policyRegulationScore = get_percent(
    country.fields["Policy & Regulation"]
  );
  const activeGovermentPromotion = get_percent(
    country.fields[
      "1.1 Active Government Promotion"
    ]
  );
  const activePolicyPromotion = get_percent(
    country.fields["1.2 Active Policy Promotion"]
  );
  const digitalEconomyRegulation = get_percent(
    country.fields[
      "1.3 Digital Economy Regulation"
    ]
  );

  // Infrastructure
  const infrastructureScore = get_percent(
    country.fields["Infrastructure"]
  );
  const idInfrastructure = get_percent(
    country.fields["2.1 ID Infrastructure"]
  );
  const connectivity = get_percent(
    country.fields["2.2 Connectivity"]
  );
  const usageAndOwnership = get_percent(
    country.fields["2.3 ICT Usage and Ownership"]
  );
  const digitalPayments = get_percent(
    country.fields["2.4 Digital Payments"]
  );

  // Innovation
  const innovationScore = get_percent(
    country.fields["Innovation"]
  );
  const communityDevelopment = get_percent(
    country.fields[
      "3.1 Innovation Community Development"
    ]
  );
  const levelOfSkills = get_percent(
    country.fields["3.2 Level of Skills"]
  );
  const supportingInfrastructure = get_percent(
    country.fields[
      "3.3 Supporting Infrastructure"
    ]
  );
  const investment = get_percent(
    country.fields["3.4 Investment"]
  );

  // Skils
  const skillScore = get_percent(
    country.fields["Skills"]
  );
  const basicSkills = get_percent(
    country.fields["4.1 Basic Skills"]
  );
  const digitalLiteracy = get_percent(
    country.fields["4.2 Digital Literacy"]
  );
  const financialLiteracy = get_percent(
    country.fields["4.3 Financial Literacy"]
  );

  const policyAndRegulation = [
    {
      Block: "Active Government Promotion",
      score: activeGovermentPromotion,
    },
    {
      Block: "Active Policy Promotion",
      score: activePolicyPromotion,
    },
    {
      Block: "Existing Regulation",
      score: digitalEconomyRegulation,
    },
  ];

  const infrastructure = [
    {
      Block: "ID Infrastructure",
      score: idInfrastructure,
    },
    {
      Block: "Connectivity",
      score: connectivity,
    },
    {
      Block: "ICT Usage and Ownership",
      score: usageAndOwnership,
    },
    {
      Block: "Digital Payments",
      score: digitalPayments,
    },
  ];
  const innovation = [
    {
      Block: "Community Development",
      score: communityDevelopment,
    },
    {
      Block: "Level of Skills",
      score: levelOfSkills,
    },
    {
      Block: "Supporting Environment",
      score: supportingInfrastructure,
    },
    {
      Block: "Financing",
      score: investment,
    },
  ];
  const skills = [
    {
      Block: "Basic Skills",
      score: basicSkills,
    },
    {
      Block: "Digital Literacy",
      score: digitalLiteracy,
    },
    {
      Block: "Financial Literacy",
      score: financialLiteracy,
    },
  ];

  const pR = {
    name: "Policy and Regulation",
    color: "#F1B844",
  };
  const inf = {
    name: "Infrastructure",
    color: "#215686",
  };
  const innov = {
    name: "Innovation",
    color: "#517542",
  };
  const sk = { name: "Skills", color: "#ED703A" };
  const sunburstData = [
    policyRegulationScore,
    infrastructureScore,
    innovationScore,
    skillScore,
    activeGovermentPromotion,
    activePolicyPromotion,
    digitalEconomyRegulation,
    idInfrastructure,
    connectivity,
    usageAndOwnership,
    digitalPayments,
    communityDevelopment,
    levelOfSkills,
    supportingInfrastructure,
    investment,
    basicSkills,
    digitalLiteracy,
    financialLiteracy,
  ];

  const scores = [
    {
      column:
        "Digital Inclusiveness -  Rural (%)",
      "Digital Inclusiveness": get_percent(
        country.fields[
          "Digital Inclusiveness - Rural"
        ]
      ),
      "Digital Divide": Math.abs(
        get_percent(
          country.fields[
            "Digital Inclusiveness - Rural"
          ]
        ) - 100
      ),
      name: "Rural",
    },
    {
      column:
        "Digital Inclusiveness -  Women (%)",
      "Digital Inclusiveness": get_percent(
        country.fields[
          "Digital Inclusiveness - Women"
        ]
      ),
      "Digital Divide": Math.abs(
        get_percent(
          country.fields[
            "Digital Inclusiveness - Women"
          ]
        ) - 100
      ),
      name: "Women",
    },
    {
      column:
        "Digital Inclusiveness -  Youth (%)",
      "Digital Inclusiveness": get_percent(
        country.fields[
          "Digital Inclusiveness - Youth"
        ]
      ),
      "Digital Divide": Math.abs(
        get_percent(
          country.fields[
            "Digital Inclusiveness - Youth"
          ]
        ) - 100
      ),
      name: "Youth",
    },
    {
      column:
        "Digital Inclusiveness -  Elderly (%)",
      "Digital Inclusiveness": get_percent(
        country.fields[
          "Digital Inclusiveness - Elderly"
        ]
      ),
      "Digital Divide": Math.abs(
        get_percent(
          country.fields[
            "Digital Inclusiveness - Elderly"
          ]
        ) - 100
      ),
      name: "Elderly",
    },
    {
      column:
        "Digital Inclusiveness -  Refugees (%)",
      "Digital Inclusiveness": get_percent(
        country.fields[
          "Digital Inclusiveness - Refugees"
        ]
      ),
      "Digital Divide": Math.abs(
        get_percent(
          country.fields[
            "Digital Inclusiveness - Refugees"
          ]
        ) - 100
      ),
      name: "Refugees",
    },

    {
      column:
        "Digital Inclusiveness -  Migrants (%)",
      "Digital Inclusiveness": get_percent(
        country.fields[
          "Digital Inclusiveness - Migrants"
        ]
      ),
      "Digital Divide": Math.abs(
        get_percent(
          country.fields[
            "Digital Inclusiveness - Migrants"
          ]
        ) - 100
      ),
      name: "Migrants",
    },
    {
      column:
        "Digital Inclusiveness -  Disabled (%)",
      "Digital Inclusiveness": get_percent(
        country.fields[
          "Digital Inclusiveness - Disabled"
        ]
      ),
      "Digital Divide": Math.abs(
        get_percent(
          country.fields[
            "Digital Inclusiveness - Disabled"
          ]
        ) - 100
      ),
      name: "Disabled",
    },

    {
      column:
        "Digital Inclusiveness -  MSMEs (%)",
      "Digital Inclusiveness": get_percent(
        country.fields[
          "Digital Inclusiveness - MSMEs"
        ]
      ),
      "Digital Divide": Math.abs(
        get_percent(
          country.fields[
            "Digital Inclusiveness - MSMEs"
          ]
        ) - 100
      ),
      name: "MSMEs",
    },
  ];

  const score_keys = [
    "Digital Inclusiveness",
    "Digital Divide",
  ];

  console.log(country);

  if (isFetched) {
    return (
      <>
        <MetaDecorator
          title={`${country.fields["Country"]} - Inclusive Digital Economy Scorecard`}
          description="The Inclusive Digital Economy Scorecard (IDES) is a strategic performance and policy tool that has been developed to support countries in better understanding and monitoring the status of their digital transformation, with a view to helping them make it more inclusive. The IDES identifies the key market constraints hindering the development of an inclusive digital economy and helps governments to set the right priorities with public and private stakeholders, to foster accelerated development of a digital economy that leaves no one behind."
          imageUrl={
            "https://www.uncdf.org/download/file/8193?defaultFile=%2FDefaultImages%2FdefaultImage.png&thumbnail=False&cultureId=127&useLarge=true"
          }
          imageAlt={"UNCDF Logo"}
        />
        <section>
          <div className="container mx-auto px-4 h-auto mt-4">
            <div className="ml-4">
              <CountryHeader
                flagUrl={
                  country.fields["Flag"][0].url
                }
                year={year}
                countryName={
                  country.fields["Country"]
                }
                status_description={
                  country.fields["Clean"]
                }
                status={country.fields["Status"]}
                email={country.fields["Email"]}
                contact={country.fields["Name"]}
                id={country.fields["CountryCode"]}
                shareUrl={
                  window.location.host +
                  window.location.pathname +
                  window.location.search
                }
              />
            </div>
            <div className="flex justify-between p-4 items-center">
              {availableYears ? (
                <YearDropdown
                  years={availableYears}
                  setYear={setYear}
                  activeYear={year}
                />
              ) : null}
              {country.fields.hasOwnProperty(
                "Report"
              ) ? (
                <a
                  className="border p-2 rounded bg-brand text-white font-semibold hover:bg-brand/60 transition-opacity"
                  href={country.fields["Report"]}
                >
                  View Report
                </a>
              ) : null}
            </div>
            <div className="grid xl:grid-cols-2 lg:grid-cols-2 grid-cols-1 justify-center items-center mt-4 text-gray-700 gap-2">
              <Radial data={data} />
              <div className="bg-gray-50 border rounded p-4 shadow">
                <div className="h-auto w-full">
                  <NewPage props={sunburstData} />
                </div>
                <div className="my-4 w-3/4 mx-auto text-center">
                  <h1 className="text-lg font-bold">
                    Scores by building block of a
                    digital economy
                  </h1>
                  <p>
                    Hover over the pie chart to
                    see the scores for each
                    component and sub-component.
                    Click on one component to
                    enlarge it.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="h-auto my-12 container mx-auto p-4">
            <BarStacked
              data={scores}
              score_keys={score_keys}
            />
          </div>

          <div className="bg-gray-900 w-full h-auto py-8">
            <div className="xl:container lg:container w-full mx-auto grid lg:grid-cols-2 xl:grid-cols-2 grid-cols-1">
              <Radar
                data={policyAndRegulation}
                total={get_percent(
                  country.fields[
                    "Policy & Regulation"
                  ]
                )}
                info={pR}
              />

              <Radar
                data={infrastructure}
                total={get_percent(
                  country.fields["Infrastructure"]
                )}
                info={inf}
              />

              <Radar
                data={innovation}
                total={get_percent(
                  country.fields["Innovation"]
                )}
                info={innov}
              />

              <Radar
                data={skills}
                total={get_percent(
                  country.fields["Skills"]
                )}
                info={sk}
              />
            </div>
          </div>
          <Footer show={false} isFixed={false} />
        </section>
      </>
    );
  }
};

export default Country;
