import React from 'react'

const AboutLayout = ({withBackround = false, customClass, children}) => {
  return (
    <section className={`${withBackround ? "bg-[#29388e] text-white" : " bg-white"} w-full ${customClass}`}>
      <div className="container p-4 mx-auto ">
        <div className="xl:w-3/4 lg:w-3/4 w-4/4 mx-auto space-y-4 py-8">{children}</div>
      </div>
    </section>
  );
}

export default AboutLayout